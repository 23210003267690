<template>
  <div style="width: 100vw; height: 100vh">
    <PdtViewer :src="pdfSrc"></PdtViewer>
  </div>
</template>

<script>
//import PdtViewer from "../components/PdfViewer.vue";
export default {
  components: {
    //PdtViewer,
  },
  data() {
    return {
      pdfSrc: "",
      numPages: null, // pdf 总页数
    };
  },
  mounted() {
    this.pdfSrc = "http://192.168.0.132:8080/certification_213-1.pdf"; //this.pagedata.pdfurl;
    //this.getNumPages();
  },
};
</script>